import { useState } from "react";
import { TextField, Button, Typography, Box } from "@mui/material";
import VideoPlayer from "../videoPlayer/videoPlayer";
import { useAppDataContext } from "hooks/useappdatacontext";

const CustomVideoPlayer = ({ edit }) => {
  // contexts
  const { hlsVideoUrl, setHlsVideoUrl } = useAppDataContext();
  // const [hlsVideoUrl, setHlsVideoUrl] = useState("");
  const [videoOptions, setVideoOptions] = useState(null);
  const [error, setError] = useState("");

  const handleUrlChange = (e) => {
    if (e.target.value === "") {
      console.log("triggetred");
      setHlsVideoUrl("");
      setHlsVideoUrl("");
    }
    setHlsVideoUrl(e.target.value);
  };

  const handlePlayVideo = () => {
    if (hlsVideoUrl) {
      setVideoOptions({
        autoplay: false,
        controls: true,
        muted: true,
        sources: [{ src: hlsVideoUrl, type: "application/x-mpegURL" }],
      });
      setError(""); // Clear any previous errors
    } else {
      setError("Please enter a valid video URL.");
    }
  };

  return (
    <Box
      sx={{
        p: 3,
        width: {
          xs: "100%",
          md: "100%",
          sm: "100%",
        },
        mx: "auto",
        textAlign: "center",
        border: edit ? null : "1px solid #ddd",
        borderRadius: edit ? null : 2,
        boxShadow: edit ? null : 3,
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <TextField
        variant="outlined"
        label="Enter video URL"
        value={hlsVideoUrl}
        onChange={handleUrlChange}
        onFocus={() => setError("")}
        margin="normal"
        sx={{ maxWidth: "100%" }}
      />
      <Button
        variant="contained"
        color="warning"
        onClick={handlePlayVideo}
        sx={{ height: "40px" }}
        size="small"
      >
        Play Video
      </Button>
      {error && (
        <Typography color="error" variant="body2">
          {error}
        </Typography>
      )}
      {videoOptions && (
        <Box
          sx={{
            mt: 3,
            height: edit ? "180px" : "450px",
          }}
        >
          <VideoPlayer edit={edit} options={videoOptions} />
        </Box>
      )}
    </Box>
  );
};

export default CustomVideoPlayer;
