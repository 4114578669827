import { useState, useEffect } from "react";
import { TextField, MenuItem, Typography, Alert, Button } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { LuUpload, LuFileVideo } from "react-icons/lu";
import { LuFileVideo2 } from "react-icons/lu";
import SensorsIcon from "@mui/icons-material/Sensors";
import { GoLink } from "react-icons/go";
import { TfiLink } from "react-icons/tfi";
import CustomVideoPlayer from "../customVideoPlayer/customVideoPlayer";
import StreamModal from "../streamModal/streamModal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./global.css";
import styles from "./global.module.css";

const UrlPreview = ({
  setOptionData,
  title,
  edit,
  setSelectedOfflineVideo,
  selectedRowId,
}) => {
  // states
  const [option, setOption] = useState("");
  const [youtubeStreamedUrl, setYoutubeStreamedUrl] = useState("");
  const [youtubeChannelId, setYoutubeChannelId] = useState("");
  const [youtubeLiveUrl, setYoutubeLiveUrl] = useState("");
  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [modalOpen, setModalOpen] = useState(true);
  const [errors, setErrors] = useState({
    youtubeStreamedUrl: "",
    youtubeChannelId: "",
    file: "",
  });

  const handleOptionChange = (event) => {
    const selectedOption = event.target.value;
    setOption(selectedOption);
    if (selectedOption === "customStreamUrl") {
      setModalOpen(true);
    } else {
      resetForm();
    }
  };

  const resetForm = () => {
    setYoutubeChannelId("");
    setYoutubeStreamedUrl("");
    setFile(null);
    setFilePreview(null);
    setModalOpen(false);
    setErrors({
      youtubeStreamedUrl: "",
      youtubeChannelId: "",
      file: "",
    });
  };

  const handleYoutubeStreamedUrlChange = (event) => {
    const streamedurl = event.target.value;
    const videoId = extractYouTubeVideoId(streamedurl);
    if (!videoId) {
      setErrors((prev) => ({
        ...prev,
        youtubeStreamedUrl: "Invalid YouTube URL.",
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        youtubeStreamedUrl: "",
      }));
    }
    setYoutubeStreamedUrl(streamedurl);
  };

  const handleYoutubeLiveIdChange = (event) => {
    const liveUrl = event.target.value;
    const liveId = extractYouTubeLiveId(liveUrl);
    setYoutubeLiveUrl(liveUrl);
    if (!liveId) {
      setErrors((prev) => ({
        ...prev,
        youtubeChannelId: "Invalid YouTube Live URL.",
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        youtubeChannelId: "",
      }));
    }
    setYoutubeChannelId(liveId);
  };

  const extractYouTubeLiveId = (url) => {
    const regExp = /youtube\.com\/live\/([^?&]+)/;
    const match = url.match(regExp);
    return match ? match[1] : null;
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile.type.startsWith("video/")) {
      setErrors((prev) => ({
        ...prev,
        file: "Please select a valid video file.",
      }));
      setFile(null);
      setFilePreview(null);
    } else {
      setErrors((prev) => ({
        ...prev,
        file: "",
      }));
      setFile(selectedFile);
      setFilePreview(URL.createObjectURL(selectedFile));
    }
  };

  const extractYouTubeVideoId = (url) => {
    const regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[7].length === 11 ? match[7] : null;
  };

  const videoId = extractYouTubeVideoId(youtubeStreamedUrl);

  const handleModalClose = () => setModalOpen(false);

  const handleSubmit = () => {
    // Post the selected file or URL to an API
    const postData = {
      option,
      youtubeStreamedUrl,
      // youtubeChannelId,
      youtubeLiveUrl,
      file,
    };
    setOptionData(postData);

    // Implement your API call here
  };

  useEffect(() => {
    if (
      (option === "file" && file && !errors.file) ||
      (option === "youtubeStreamedUrl" &&
        youtubeStreamedUrl &&
        !errors.youtubeStreamedUrl) ||
      (option === "youtubeChannelId" &&
        youtubeChannelId &&
        !errors.youtubeChannelId)
    ) {
      handleSubmit();
    }
  }, [option, file, youtubeStreamedUrl, youtubeChannelId, errors]);
  useEffect(() => {
    if (option === "customStreamUrl" && (title === null || title === "")) {
      toast.error("Please Enter Event Title");
      setOption(""); // Reset option if no title
    }
  }, [option, title]); // Run this effect whenever option or title changes

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <Typography variant="h6" className={styles.uploadVideoTextStyle}>
        Upload Video
      </Typography>
      <TextField
        select
        label="From"
        value={option}
        onChange={handleOptionChange}
        SelectProps={{
          IconComponent: ArrowDropDownIcon,
        }}
        variant="outlined"
        margin="normal"
        fullWidth
      >
        <MenuItem value="none">None</MenuItem>
        <MenuItem value="file" className={styles.menuItemBlock}>
          <LuUpload size={18} /> From Folder
        </MenuItem>
        <MenuItem value="youtubeStreamedUrl" className={styles.menuItemBlock}>
          <LuFileVideo2 size={18} /> Youtube Recorded
        </MenuItem>
        <MenuItem value="youtubeChannelId" className={styles.menuItemBlock}>
          <SensorsIcon /> Youtube Live
        </MenuItem>
        <MenuItem value="HLS_Url" className={styles.menuItemBlock}>
          <GoLink size={18} /> HLS URL
        </MenuItem>
        <MenuItem value="customStreamUrl" className={styles.menuItemBlock}>
          <TfiLink size={18} /> Custom Stream URL
        </MenuItem>
      </TextField>

      {option === "file" && (
        <div>
          <Button
            variant="contained"
            component="label"
            color="primary"
            margin="normal"
            startIcon={<LuFileVideo />}
          >
            Select File
            <input
              type="file"
              accept="video/*"
              hidden
              onChange={handleFileChange}
            />
          </Button>
          {errors.file && <Alert severity="error">{errors.file}</Alert>}
          {file && (
            <div>
              <Typography variant="subtitle1">File Preview:</Typography>
              <video
                src={filePreview}
                controls
                width="100%"
                style={{ maxWidth: "600px" }}
              ></video>
            </div>
          )}
        </div>
      )}

      {option === "youtubeStreamedUrl" && (
        <div>
          <TextField
            label="Enter youtube video URL"
            value={youtubeStreamedUrl}
            onChange={handleYoutubeStreamedUrlChange}
            variant="outlined"
            margin="normal"
            fullWidth
            error={!!errors.youtubeStreamedUrl}
            helperText={errors.youtubeStreamedUrl}
          />
          {youtubeStreamedUrl && !errors.youtubeStreamedUrl && (
            <div>
              <Typography variant="subtitle1">Preview:</Typography>
              <iframe
                title={`${videoId}`}
                width="100%"
                height="315"
                src={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1`}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          )}
        </div>
      )}

      {option === "youtubeChannelId" && (
        <div>
          <TextField
            label="Enter Youtube live video URL"
            value={youtubeChannelId}
            onChange={handleYoutubeLiveIdChange}
            variant="outlined"
            margin="normal"
            fullWidth
            error={!!errors.youtubeChannelId}
            helperText={errors.youtubeChannelId}
          />
          {youtubeChannelId && !errors.youtubeChannelId && (
            <div>
              <Typography variant="subtitle1">Preview:</Typography>
              <iframe
                title={`${youtubeChannelId}`}
                width="100%"
                height="315"
                src={`https://www.youtube.com/embed/${youtubeChannelId}?autoplay=1&mute=1`}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          )}
        </div>
      )}

      {option === "HLS_Url" && <CustomVideoPlayer edit={edit} />}
      {option === "customStreamUrl" && title && title !== "" && (
        <StreamModal
          edit={edit}
          selectedRowId={selectedRowId}
          open={modalOpen}
          handleClose={handleModalClose}
          title={title}
        />
      )}
    </div>
  );
};

export default UrlPreview;
