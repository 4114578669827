import { useEffect, useRef } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";

const VideoPlayerForEdit = ({ options }) => {
  const hlsPreviewVideoRef = useRef(null);
  const hlsPreviewPlayerRef = useRef(null);

  useEffect(() => {
    const initializePlayer = () => {
      if (hlsPreviewVideoRef.current && !hlsPreviewPlayerRef.current) {
        const videoNode = hlsPreviewVideoRef.current;

        // Initialize Video.js player
        hlsPreviewPlayerRef.current = videojs(videoNode, options, () => {
          console.log("Player is ready");
        });

        // Event listener for error handling
        hlsPreviewPlayerRef.current.on("error", (e) => {
          console.error("Video.js Error:", e);
        });
      }
    };

    initializePlayer();
  }, [options]);

  return (
    <div style={{ width: "80%" }}>
      <div data-vjs-player>
        <video
          ref={hlsPreviewVideoRef}
          style={{
            width: "100%",
          }}
          className="video-js"
        />
      </div>
    </div>
  );
};

export default VideoPlayerForEdit;