import { useEffect, useRef, useState } from "react";
import UserInfoTooltip from "../userInfoTooltip/userInfoTooltip";
import SidebarMenu from "../sidebarmenu";
import { useAppDataContext } from "hooks/useappdatacontext";
import { useAuthContext } from "../../hooks/useauthcontext";
import { IoIosArrowBack } from "react-icons/io";
import { FaCoins, FaVideo } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { FaHistory } from "react-icons/fa";
import styles from "./header.module.css";
import AppSidebarMenu from "../appsidebar/appsidebar";

import {
  Button,
  Dialog,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Paper,
} from "@mui/material";
import { toast } from "react-toastify";
import { BsFillSendDashFill } from "react-icons/bs";
import { LoaderCircle } from "lucide-react";

const send = {
  selectionMode: "selectionMode",
  storedMode: "storedMode",
  verificationMode: "verificationMode",
};

const Header = () => {
  //loader font
  const fontSize = "14px";
  const loaderSize = parseInt(fontSize);
  //select send credit mode
  const [sendCreditMode, setSendCreditMode] = useState("");
  const [usersList, setUserList] = useState([]);
  const [sendCredit, setSendCredit] = useState({
    eventCreditCount: 0,
    recVideoCreditCount: 0,
  });

  let verifiedMail = JSON.parse(localStorage.getItem("verifiedEmails")) || [];

  const [sendCreditsPopup, setSendCreditsPopup] = useState(false);
  const [medium, setMedium] = useState("");
  const [inputMail, setInputMail] = useState("");
  const [mailVerificationFromREsponse, setMailVerificationFromResponse] =
    useState(false);
  const [veriedUserdetails, setVeriedUserdetails] = useState({});

  const [searchByUserDetals, setSearchByUserDetals] = useState("");

  const [loader, setLoader] = useState(false);

  const [sendConfirmation, setSendConfirmation] = useState(false);

  const [mailError, setMailError] = useState(false);

  const menuRef = useRef(null);

  const navigate = useNavigate();

  // states
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const { userData } = useAuthContext();
  // contexts

  const { sendCredits, setSendCredits, getUserCredits, userCredits } =
    useAppDataContext();

  const getAllUserData = async () => {
    const urlTogetAllUsersData = `${process.env.REACT_APP_BASE_URL}/events/get_all_U/`;
    const accessToken = localStorage.getItem("accessToken");

    // Ensure the accessToken is available
    if (!accessToken) {
      console.error("No access token found.");
      return;
    }

    try {
      const response = await fetch(urlTogetAllUsersData, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (response.ok) {
        setUserList(data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getAllUserData();
    getUserCredits();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false); // Close the menu if clicked outside
      }
    };

    // Attach the event listener to the document
    document.addEventListener("click", handleClickOutside);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handlerToVerifyRecieverMail = async () => {
    if (userData && userData.email.trim(" ") !== inputMail) {
      const accessToken = localStorage.getItem("accessToken");
      const urlToVerifyInputMail = `${process.env.REACT_APP_BASE_URL}/events/verify_email/`;
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          email: inputMail.trim(" "),
        }),
      };

      try {
        const serverResponse = await fetch(urlToVerifyInputMail, options);
        if (serverResponse.ok) {
          const result = await serverResponse.json();
          setVeriedUserdetails(result);
          toast.success("Mail Varification Scuess");
          setMailVerificationFromResponse(true);
          const isTrue =
            verifiedMail.length > 0
              ? verifiedMail.some((item) => item.email === inputMail)
              : false;

          if (isTrue) {
            // Check if result.email already exists in verifiedMail to avoid duplicates
            const isDuplicate = verifiedMail.some(
              (item) => item.email === result.email
            );

            if (!isDuplicate) {
              verifiedMail.push(result); // Add the result if no duplicate found
              localStorage.setItem(
                "verifiedEmails",
                JSON.stringify(verifiedMail)
              );
            }
          } else {
            // If the email does not exist in verifiedMail, just add it
            verifiedMail.push(result);
            localStorage.setItem(
              "verifiedEmails",
              JSON.stringify(verifiedMail)
            );
          }
        } else {
          toast.error("Incorrect Mail");
        }
      } catch (e) {
        toast.error(e.message);
      }
    }
  };

  const handleToSendCreditsToOtherUser = async () => {
    setLoader(true);
    const accessToken = localStorage.getItem("accessToken");
    const urlToSendCredits = `${process.env.REACT_APP_BASE_URL}/events/send_credits/`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        email: inputMail.trim(" "),
        credits: sendCredit.eventCreditCount,
        vid_credits: sendCredit.recVideoCreditCount,
      }),
    };

    try {
      const response = await fetch(urlToSendCredits, options);
      if (response.ok) {
        setSendConfirmation(false);
        setSendCredits(false);
        setInputMail("");
        setSendCredit({
          eventCreditCount: 0,
          recVideoCreditCount: 0,
        });
        setMedium("");
        setSendCreditsPopup();
        setMailVerificationFromResponse(false);
        getUserCredits();
        toast.success("You have Successfully Sent your credits");
        setLoader(false);
      }
    } catch (e) {
      setLoader(false);

      console.log(e.error, e.message);
    }
  };

  const handleOnCloseSendCredits = () => {
    setSendCredits(false);
    setSendCredit({
      eventCreditCount: 0,
      recVideoCreditCount: 0,
    });
    setInputMail("");
    setMailVerificationFromResponse(false);
  };

  const renderDialogToSendCreditsByMail = () => {
    let receiverDetailsOne;
    let userDetailsfromStoredDetails;

    if (inputMail !== "") {
      receiverDetailsOne = usersList.filter((each) => each.email === inputMail);
      const storedDetails =
        JSON.parse(localStorage.getItem("verifiedEmails")) || [];
      userDetailsfromStoredDetails = storedDetails.filter(
        (each) => each.email === inputMail
      );

      userDetailsfromStoredDetails = userDetailsfromStoredDetails.filter(
        (each) => each.email !== userData.email
      );
    }
    // console.log(userData.email);

    return (
      <Dialog
        fullWidth
        open={medium === "mail"}
        sx={{ backgroundColor: "transparent", minWidth: "300px" }}
      >
        <DialogTitle
          sx={{
            color: "white",
            backgroundColor: "#333",
            textAlign: "center",
            padding: "10px",
            display: "flex",
            justifyContent: "space-between", // Push content to the edges
            alignItems: "center", // Vertically center content
          }}
        >
          <Button
            onClick={() => {
              setMedium("");
              setMailVerificationFromResponse(false);
              setInputMail("");
              setSendCredit({
                eventCreditCount: 0,
                recVideoCreditCount: 0,
              });
            }}
            sx={{
              color: "white",
            }}
          >
            <IoIosArrowBack />
          </Button>

          {/* Centered Title */}
          <p style={{ flex: 1, textAlign: "center", marginRight: "70px" }}>
            Credits Transfer
          </p>
        </DialogTitle>

        <div style={{ padding: "20px", width: "100%" }}>
          {userCredits.credits === 0 && userCredits.vid_credits === 0 ? (
            <div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "0px 15px",
                  marginBottom: "20px",
                  flexWrap: "wrap",
                }}
              >
                <p style={{ margin: "5px", flex: "1" }}>
                  Event Credits: {userCredits.credits}
                </p>
                <p style={{ margin: "5px" }}>
                  Recording Credits: {userCredits.vid_credits}
                </p>
              </div>
              <p style={{ textAlign: "center" }}>
                Plase Maintain Suffuient Credits
              </p>
            </div>
          ) : (
            <>
              {!mailVerificationFromREsponse && (
                <div>
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "5px",
                    }}
                  >
                    <input
                      type="text"
                      placeholder="Input Receiver Mail"
                      style={{
                        padding: "10px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                        width: "70%",
                      }}
                      value={inputMail}
                      onChange={(e) => {
                        setInputMail(e.target.value);
                      }}
                    />

                    <button
                      style={{
                        width: "30%",
                        padding: "10px",
                        backgroundColor: "#4caf50",
                        color: "white",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handlerToVerifyRecieverMail();
                        setSendCreditMode(send.verificationMode);
                      }}
                    >
                      Verify
                    </button>
                  </div>
                  {verifiedMail.length !== 0 && (
                    <p className={styles.sectionTitle}>Recent Verified Mails</p>
                  )}
                  <ul className={styles.verifiedEmailsList}>
                    {Array.isArray(verifiedMail) && verifiedMail.length > 0 ? (
                      verifiedMail.map((eachMail) => {
                        if (userData && eachMail.email !== userData.email) {
                          return (
                            <li
                              className={styles.emailItem}
                              key={eachMail.email}
                            >
                              <FaHistory className={styles.historyIcon} />
                              <button
                                onClick={() => {
                                  if (usersList.length === 0) {
                                    handlerToVerifySearchedRecieverMail(
                                      eachMail.email
                                    );
                                  }
                                  setSendCreditMode(send.storedMode);
                                  setInputMail(eachMail.email);
                                  setMedium("mail");
                                  setMailVerificationFromResponse(true);
                                }}
                                className={styles.emailButton}
                              >
                                {eachMail.email}
                              </button>
                            </li>
                          );
                        }
                        return null; // Return null if condition is not met to avoid undefined.
                      })
                    ) : (
                      <p>No Recent Data</p>
                    )}
                  </ul>

                  {userData && userData.email.trim(" ") === inputMail && (
                    <strong style={{ color: "red" }}>
                      Your can't transfer to your self.
                    </strong>
                  )}
                </div>
              )}

              {mailVerificationFromREsponse && (
                <>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <h3 style={{ textAlign: "center", marginBottom: "3px" }}>
                      Reciver Details
                    </h3>
                  </div>
                  <strong>
                    {veriedUserdetails &&
                      sendCreditMode === send.selectionMode &&
                      `${veriedUserdetails.first_name} ${veriedUserdetails.last_name}`}

                    {userDetailsfromStoredDetails &&
                      sendCreditMode === send.storedMode &&
                      `${userDetailsfromStoredDetails[0].first_name} ${userDetailsfromStoredDetails[0].last_name}`}

                    {receiverDetailsOne?.length > 0 &&
                      sendCreditMode === send.verificationMode &&
                      `${receiverDetailsOne[0].first_name} ${receiverDetailsOne[0].last_name}`}
                  </strong>
                  <br />
                  <strong>
                    {veriedUserdetails &&
                      sendCreditMode === send.selectionMode &&
                      veriedUserdetails.email}

                    {userDetailsfromStoredDetails &&
                      sendCreditMode === send.storedMode &&
                      userDetailsfromStoredDetails[0].email}

                    {receiverDetailsOne?.length > 0 &&
                      sendCreditMode === send.verificationMode &&
                      receiverDetailsOne[0].email}
                  </strong>
                  <br />
                  <strong>
                    {veriedUserdetails &&
                      sendCreditMode === send.selectionMode &&
                      veriedUserdetails.phone_number}

                    {userDetailsfromStoredDetails &&
                      sendCreditMode === send.storedMode &&
                      userDetailsfromStoredDetails[0].phone_number}

                    {receiverDetailsOne?.length > 0 &&
                      sendCreditMode === send.verificationMode &&
                      receiverDetailsOne[0].phone_number}
                  </strong>

                  <hr />

                  {/* Table with credit types */}
                  <TableContainer style={{ overflowX: "auto" }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ padding: "0px 16px" }}>
                            Credit Type
                          </TableCell>
                          <TableCell sx={{ padding: "0px 16px" }}>
                            Enter Quantity
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {userCredits?.credits >= 1 && (
                          <TableRow>
                            <TableCell>Event Credits</TableCell>
                            <TableCell
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                padding: "0px 16px",
                              }}
                            >
                              <TextField
                                type="number"
                                disabled={userCredits.credits === 0}
                                value={sendCredit.eventCreditCount}
                                onChange={(e) => {
                                  if (e.target.value > userCredits.credits) {
                                    setSendCredit({
                                      ...sendCredit,
                                      eventCreditCount: userCredits.credits,
                                    });
                                    toast.error(
                                      "Events Input value should be less than available"
                                    );
                                  } else {
                                    setSendCredit({
                                      ...sendCredit,
                                      eventCreditCount: e.target.value,
                                    });
                                  }
                                }}
                                variant="outlined"
                                size="small"
                                inputProps={{
                                  min:
                                    sendCredit.recVideoCreditCount > 0 ? 0 : 1,
                                  max: userCredits.credits,
                                }}
                                sx={{
                                  width: "100px !important",
                                  marginTop: "3px",
                                }}
                              />
                              <p style={{ marginLeft: "2px", color: "red" }}>
                                Avl: {userCredits.credits}
                              </p>
                            </TableCell>
                          </TableRow>
                        )}

                        {userCredits?.credits >= 1 && (
                          <TableRow>
                            <TableCell sx={{ padding: "0px 16px" }}>
                              Recording Video Credits
                            </TableCell>
                            <TableCell
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                padding: "0px 16px",
                              }}
                            >
                              <TextField
                                type="number"
                                value={sendCredit.recVideoCreditCount}
                                disabled={userCredits.vid_credits === 0}
                                onChange={(e) => {
                                  if (
                                    e.target.value > userCredits.vid_credits
                                  ) {
                                    setSendCredit({
                                      ...sendCredit,
                                      recVideoCreditCount:
                                        userCredits.vid_credits,
                                    });
                                    toast.error(
                                      "Events Input value should be less than available"
                                    );
                                  } else {
                                    setSendCredit({
                                      ...sendCredit,
                                      recVideoCreditCount: e.target.value,
                                    });
                                  }
                                }}
                                variant="outlined"
                                size="small"
                                inputProps={{
                                  min: sendCredit.eventCreditCount > 0 ? 0 : 1,
                                  max: userCredits.vid_credits,
                                }}
                                sx={{
                                  width: "100px !important",
                                  marginTop: "3px",
                                }}
                              />
                              <p style={{ marginLeft: "2px", color: "red" }}>
                                Avl: {userCredits.vid_credits}
                              </p>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  {/* Action Buttons */}
                  <div
                    style={{
                      marginTop: "20px",
                      display: "flex",
                      justifyContent: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <Button
                      onClick={() => {
                        if (
                          sendCredit.eventCreditCount === 0 &&
                          sendCredit.recVideoCreditCount === 0
                        ) {
                          return toast.error("Please Input Credits");
                        } else {
                          setSendConfirmation(true);
                        }
                      }}
                      sx={{
                        backgroundColor: "#4caf50",
                        color: "white",
                        "&:hover": { backgroundColor: "#388e3c" },
                        flex: "1 1 48%",
                        marginBottom: "10px",
                      }}
                    >
                      Send
                    </Button>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </Dialog>
    );
  };

  const renderSendCreditsConfirmationDialog = () => {
    let receiverDetailsOne;
    let userDetailsfromStoredDetails;

    if (inputMail !== "") {
      receiverDetailsOne = usersList.filter((each) => each.email === inputMail);
      const storedDetails =
        JSON.parse(localStorage.getItem("verifiedEmails")) || [];
      userDetailsfromStoredDetails = storedDetails.filter(
        (each) => each.email === inputMail
      );
    }

    return (
      <Dialog
        open={sendConfirmation}
        fullWidth
        style={{
          minWidth: "300px",
          background: "none",
          padding: "20px",
          borderRadius: "8px",
          textAlign: "center",
          padding: "50px 0px",
          margin: "20px 0px",
        }}
      >
        <div style={{ padding: "40px 10px" }}>
          <TableContainer
            component={Paper}
            sx={{ maxWidth: "500px", margin: "auto" }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    colSpan={2}
                    sx={{ textAlign: "center", fontWeight: "bold" }}
                  >
                    You are Sending to
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Name:
                  </TableCell>
                  <TableCell>
                    <strong>
                      {veriedUserdetails &&
                        sendCreditMode === send.selectionMode &&
                        `${veriedUserdetails.first_name} ${veriedUserdetails.last_name}`}

                      {userDetailsfromStoredDetails &&
                        sendCreditMode === send.storedMode &&
                        `${userDetailsfromStoredDetails[0].first_name} ${userDetailsfromStoredDetails[0].last_name}`}

                      {receiverDetailsOne?.length > 0 &&
                        sendCreditMode === send.verificationMode &&
                        `${receiverDetailsOne[0].first_name} ${receiverDetailsOne[0].last_name}`}
                    </strong>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell component="th" scope="row">
                    Mail ID:
                  </TableCell>
                  <TableCell>
                    <strong>
                      {veriedUserdetails &&
                        sendCreditMode === send.selectionMode &&
                        veriedUserdetails.email}

                      {userDetailsfromStoredDetails &&
                        sendCreditMode === send.storedMode &&
                        userDetailsfromStoredDetails[0].email}

                      {receiverDetailsOne?.length > 0 &&
                        sendCreditMode === send.verificationMode &&
                        receiverDetailsOne[0].email}
                    </strong>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell component="th" scope="row">
                    Number:
                  </TableCell>
                  <TableCell>
                    <strong>
                      {veriedUserdetails &&
                        sendCreditMode === send.selectionMode &&
                        veriedUserdetails.phone_number}

                      {userDetailsfromStoredDetails &&
                        sendCreditMode === send.storedMode &&
                        userDetailsfromStoredDetails[0].phone_number}

                      {receiverDetailsOne?.length > 0 &&
                        sendCreditMode === send.verificationMode &&
                        receiverDetailsOne[0].phone_number}
                    </strong>
                  </TableCell>
                </TableRow>

                {sendCredit.eventCreditCount > 0 && (
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Event Credits:
                    </TableCell>
                    <TableCell>
                      <strong>{sendCredit.eventCreditCount}</strong>
                    </TableCell>
                  </TableRow>
                )}

                {/* Add Recording Video Credit Count row if greater than 0 */}
                {sendCredit.recVideoCreditCount > 0 && (
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Recording Video:
                    </TableCell>
                    <TableCell>
                      <strong>{sendCredit.recVideoCreditCount}</strong>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>{" "}
          {/* {sendCredit.eventCreditCount > 0 && (
          <p style={{ fontSize: "0.85rem" }}>
            Event Credits: <strong>{sendCredit.eventCreditCount}</strong>{" "}
          </p>
        )}
        {sendCredit.recVideoCreditCount > 0 && (
          <p style={{ fontSize: "0.85rem" }}>
            Recording Video: <strong>{sendCredit.recVideoCreditCount}</strong>
          </p>
        )} */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              padding: "10px 0",
              margin: "10px",
            }}
          >
            <Button
              disabled={loader}
              style={{
                backgroundColor: "#f44336",
                color: "#fff",
                padding: "10px 20px",
              }}
              onClick={() => {
                setSendConfirmation(false);
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={loader}
              onClick={() => {
                handleToSendCreditsToOtherUser();
              }}
              style={{
                width: "90px",
                backgroundColor: "#4caf50",
                color: "#fff",
                padding: "10px 20px",
                fontSize: "14px",
              }}
            >
              {loader ? (
                <div className={styles.loaderTailSpin}></div>
              ) : (
                "Confirm"
              )}
            </Button>
          </div>
        </div>
      </Dialog>
    );
  };

  const renderDailogueToSelectSearchByuserOrEnterMail = () => {
    return (
      <Dialog fullWidth open={sendCreditsPopup} sx={{ minWidth: "300px" }}>
        <DialogTitle
          sx={{
            color: "white",
            textAlign: "center",
            padding: "10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => {
              setSendCreditsPopup(false);
              setInputMail("");
              setSearchByUserDetals("");
            }}
            sx={{
              color: "black",
            }}
          >
            <IoIosArrowBack />
            Back
          </Button>
          <p style={{ flex: 1, textAlign: "center", marginRight: "70px" }}>
            Send Credits via
          </p>
        </DialogTitle>
        <div className={styles.searchByAndManualBtn}>
          <Button
            onClick={() => {
              setMedium("search");
              setSendCredits(!sendCredits);
            }}
            sx={{
              backgroundColor: "#1976d2",
              color: "white",
              padding: "10px 20px",
              borderRadius: "8px",
              "&:hover": {
                backgroundColor: "#1565c0",
              },
              fontWeight: "bold",
              marginBottom: "10px",
              textTransform: "none",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              transition: "all 0.3s ease",
            }}
          >
            Search By User Details
          </Button>

          <Button
            onClick={() => {
              setMedium("mail");
              setSendCredits(!sendCredits);
              setMailVerificationFromResponse(false);
            }}
            sx={{
              backgroundColor: "#4caf50",
              color: "white",
              padding: "10px 20px",
              borderRadius: "8px",
              "&:hover": {
                backgroundColor: "#388e3c",
              },
              fontWeight: "bold",
              marginBottom: "10px",
              textTransform: "none",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              transition: "all 0.3s ease",
            }}
          >
            By Receiver Mail Verification
          </Button>
        </div>
      </Dialog>
    );
  };

  const handlerToVerifySearchedRecieverMail = async (mail) => {
    setInputMail(mail);
    try {
      const accessToken = localStorage.getItem("accessToken");
      const urlToVerifyInputMail = `${process.env.REACT_APP_BASE_URL}/events/verify_email/`;
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          email: mail,
        }),
      };
      const serverResponse = await fetch(urlToVerifyInputMail, options);
      if (serverResponse.ok) {
        const result = await serverResponse.json();
        // console.log(result);
        setVeriedUserdetails(result);
        setSearchByUserDetals("");
        setMedium("mail");
        setMailVerificationFromResponse(true);
      }
    } catch (e) {
      toast.error("SearchIn in mail Error");
    }
  };

  const renderDialogueToSearchUser = () => {
    let fiteredList;
    fiteredList = usersList.filter(
      (each) =>
        each.first_name
          .toLowerCase()
          .includes(searchByUserDetals.toLowerCase()) ||
        each.phone_number.includes(searchByUserDetals) ||
        each.last_name
          .toLowerCase()
          .includes(searchByUserDetals.toLowerCase()) ||
        each.email.includes(searchByUserDetals.toLowerCase())
    );

    // ||userData && !userData.email.includes(searchByUserDetals);
    fiteredList = fiteredList.filter((each) => each.email !== userData?.email);

    return (
      <Dialog
        fullWidth
        open={medium === "search"}
        sx={{
          margin: "auto",
          maxHeight: "80% !important",
          overflow: "hidden",
        }}
      >
        <DialogTitle
          sx={{
            color: "white",
            backgroundColor: "#333",
            textAlign: "center",
            padding: "10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            position: "sticky",
            top: 0,
            zIndex: 1,
          }}
        >
          <Button
            onClick={() => {
              setInputMail("");
              setMedium(false);
              setSearchByUserDetals("");
              setMailVerificationFromResponse(false);
            }}
            sx={{
              color: "white",
            }}
          >
            <IoIosArrowBack />
          </Button>
          <p style={{ flex: 1, textAlign: "center", marginRight: "70px" }}>
            Search by user Details
          </p>
        </DialogTitle>

        <div
          style={{
            padding: "10px 20px",
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <input
            style={{
              width: "100%",
              padding: "10px 15px",
              fontSize: "16px",
              borderRadius: "8px",
              border: "1px solid #ccc",
              backgroundColor: "#f9f9f9",
              color: "#333",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              outline: "none",
              transition: "border-color 0.3s, box-shadow 0.3s",
              marginBottom: "10px",
            }}
            placeholder="Search by Number/Name/Mail ID"
            id="searchUserDetails"
            value={searchByUserDetals}
            onChange={(e) => setSearchByUserDetals(e.target.value)}
          />
          <div
            style={{
              padding: "10px",
              overflowY: "auto",
              maxHeight: "calc(60vh - 140px)",
            }}
          >
            {searchByUserDetals ? (
              <>
                {fiteredList.length === 0 ? (
                  <div>
                    <p style={{ color: "red", textAlign: "center" }}>
                      Oops. Try With other keyword!
                    </p>
                  </div>
                ) : (
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <strong style={{ fontSize: "0.85rem" }}>
                              Name
                            </strong>
                          </TableCell>
                          <TableCell>
                            <strong style={{ fontSize: "0.85rem" }}>
                              Number
                            </strong>
                          </TableCell>
                          <TableCell>
                            <strong style={{ fontSize: "0.85rem" }}>
                              Email
                            </strong>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {fiteredList.map((eachUser, index) => (
                          <TableRow key={index}>
                            <TableCell
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "3px",
                              }}
                            >
                              <input
                                type="radio"
                                id={eachUser.email}
                                value={eachUser.email}
                                onClick={() => {
                                  handlerToVerifySearchedRecieverMail(
                                    eachUser.email
                                  );
                                  setSendCreditMode(send.selectionMode);
                                }}
                                name="serachUserDetails"
                              />
                              <label
                                htmlFor={eachUser.email}
                                style={{ fontSize: "0.75rem" }}
                              >
                                {eachUser.first_name} {eachUser.last_name}
                              </label>
                            </TableCell>
                            <TableCell>
                              <p style={{ fontSize: "0.75rem" }}>
                                {eachUser.phone_number}
                              </p>
                            </TableCell>
                            <TableCell sx={{ overflow: "auto" }}>
                              {/* <button
                                onClick={() => {
                                  setInputMail(eachUser.email);
                                  setMedium("mail");
                                  setMailVerificationFromResponse(true);
                                }}
                                style={{ fontSize: "0.75rem" }}
                              > */}
                              {eachUser.email}
                              {/* </button> */}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </>
            ) : (
              <p style={{ margin: "5px", textAlign: "center" }}>
                Plsase Input User Details
              </p>
            )}
          </div>
        </div>
      </Dialog>
    );
  };

  return (
    <main
      style={{ boxShadow: "0 10px 15px rgba(0, 0, 0, 0.3)" }}
      className={styles.headerContainerStyle}
      ref={menuRef}
    >
      <div className={styles.headerContentStyle}>
        <motion.div
          className={styles.navbarLogo}
          whileHover={{ scale: 1.05 }}
          onClick={() => navigate("/")}
        >
          <img
            style={{ width: "150px", marginTop: "6px" }}
            src="https://d1gmn9lhl3xm2s.cloudfront.net/static/logo/neolLogo.jpg"
            alt="nav-logo"
          />
        </motion.div>

        {/* <img
          onClick={() => {
            navigate(routeNames.dashBoard);
          }}
          style={{ height: "50px", width: "100px" }}
          src={page_logo}
        /> */}
      </div>
      <div>
        <SidebarMenu />
      </div>
      <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
        <button
          onClick={() => {
            setSendCreditsPopup(!sendCreditsPopup);
          }}
          className={styles.sendCreditsTextContainer}
        >
          <BsFillSendDashFill className={styles.menuIconStyle} />
          <p className={styles.menuItemText}>Send Credits</p>
        </button>

        <div className={styles.dropdown}>
          <button className={styles.dropdownButton} onClick={toggleDropdown}>
            Credits <span className={styles.dropdownIcon}>&#9660;</span>
          </button>
          {isOpen && (
            <div className={styles.dropdownContent}>
              <div className={styles.creditOptionStyle}>
                <FaCoins className={styles.creditsIcon} />
                <p className={styles.creditOptionTextStyle}>
                  Event Credits: {userCredits.credits}
                </p>
              </div>
              <div className={styles.creditOptionStyle}>
                <FaVideo className={styles.creditsIcon} />
                <p className={styles.creditOptionTextStyle}>
                  Channel Credits: {userCredits.vid_credits}
                </p>
              </div>
            </div>
          )}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <UserInfoTooltip />
          <AppSidebarMenu />
          {renderDailogueToSelectSearchByuserOrEnterMail()}
          {renderDialogueToSearchUser()}
          {renderDialogToSendCreditsByMail()}
          {renderSendCreditsConfirmationDialog()}
        </div>
      </div>
    </main>
  );
};

export default Header;
