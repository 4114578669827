import useRazorpay from "react-razorpay";
import { motion } from "framer-motion";
import { MdCancel } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";
import { useEffect, useState, useRef } from "react";
import axiosInstance from "utils/axios";
import { useAppDataContext } from "hooks/useappdatacontext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./styles.module.css";
import { Dialog, DialogTitle } from "@mui/material";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const Payments = () => {
  const { pricingData, getPricingData, getUserCredits } = useAppDataContext();
  // states
  const [paymentFailed, setPaymentFailed] = useState(false);
  const paymentFailedRef = useRef(paymentFailed);
  const [Razorpay] = useRazorpay();
  const [loading, setLoading] = useState(false);
  const [selectedPlanDetials, setSelectedPlanDetails] = useState([]);
  const [paymentConfirmationWindow, setpaymentConfirmationWindow] =
    useState(false);

  // const token = process.env.NEXT_PUBLIC_API_TOKEN;
  const { REACT_APP_BASE_URL } = process.env;
  const api = `${REACT_APP_BASE_URL}/pay/make_payment/`;
  const verifyapi = `${REACT_APP_BASE_URL}/pay/verify_payment/`;
  // Keep the ref updated with the current state
  useEffect(() => {
    paymentFailedRef.current = paymentFailed;
  }, [paymentFailed]);

  // functions

  const makePaymentPop = (plan) => {
    setpaymentConfirmationWindow(true);
    setLoading(true)

    // Calculate the final price based on Sale Price or Actual Price
    let final_price =
      plan.Sale_price === 0 ? plan.actual_price : plan.Sale_price;

    // Calculate GST based on the final price and GST percentage
    const gst_rate = (
      (final_price * Number(plan.Gst_percentage)) /
      100
    ).toFixed(2);

    // Update selected plan details with necessary info
    setSelectedPlanDetails({
      ...selectedPlanDetials,
      id: plan.id,
      name: plan.name,
      Sale_price: final_price,
      Gst_percentage: plan.Gst_percentage,
      gst_margin: gst_rate,
      service_charge: plan.service_charge,
      final_amount_pay: plan.finalpricewithcharges,
    });
  };

  // console.log(selectedPlanDetials);

  const makePayment = async (plan) => {
    // setpaymentConfirmationWindow(false);
    try {
      const response = await axiosInstance.post(api, {
        price_id: selectedPlanDetials.id,
        product_name: selectedPlanDetials.name,
        currency: "INR",
      });

      if (response?.data) {
        const payId = selectedPlanDetials.id;
        const { order_id, amount, currency, entity } = response.data;
        handlePayment({ order_id, amount, currency, entity, payId });
      } else {
        setLoading(false);
        toast.error("Payment failed");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error making payment");
    } finally {
      setLoading(false);
    }
  };

  const handlePayment = async ({
    order_id,
    amount,
    currency,
    entity,
    payId,
  }) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert(
        "Failure loading the Razorpay SDK. Please make sure you are connected to the internet"
      );
      return;
    }

    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY,
      amount: amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: currency,
      name: "Infinitum Tech",
      description: entity,
      order_id: order_id,
      handler: async (response) => {
        try {
          await axiosInstance.post(verifyapi, {
            pricing_id: payId,
            razorpay_paymentId: response.razorpay_payment_id,
            razorpay_orderId: response.razorpay_order_id,
            razorpay_signature: response.razorpay_signature,
          });
          getUserCredits();
          toast.success("Payment Successful");
          setpaymentConfirmationWindow(!paymentConfirmationWindow);
          setLoading(false)
        } catch (err) {
          toast.error("Error verifying payment");
          console.error("Verification error:", err);
        }
      },
      theme: {
        color: "#3399cc",
      },
      modal: {
        ondismiss: async () => {
          // Handle the case when the payment is closed without completing it
          if (paymentFailedRef.current) return; // Use ref for accurate check
          try {
            if (!paymentFailedRef.current) {
              await axiosInstance.post(verifyapi, {
                pricing_id: payId,
                razorpay_orderId: order_id,
                status: "cancelled",
              });
              toast.warning("Payment cancelled");
            }
          } catch (err) {
            console.error("Cancellation error:", err);
          }
        },
      },
    };

    const paymentObject = new window.Razorpay(options);

    paymentObject.on("payment.failed", async (response) => {
      setPaymentFailed(true); // Update state immediately
      try {
        await axiosInstance.post(verifyapi, {
          pricing_id: payId,
          razorpay_orderId: order_id,
          status: "failed",
          error: response.error,
        });
        toast.error("Payment failed");
      } catch (err) {
        console.error("Failed payment error:", err);
      }
    });

    paymentObject.open();
  };

  useEffect(() => {
    const fetchPricingData = async () => {
      await getPricingData();
    };
    fetchPricingData();
  }, []);

  const eventPacks = pricingData.filter(
    (plan) => plan.num_Events > 0 && plan.num_Records === 0
  );
  const videoPacks = pricingData.filter(
    (plan) => plan.num_Records > 0 && plan.num_Events === 0
  );
  const comboPacks = pricingData.filter(
    (plan) => plan.num_Events > 0 && plan.num_Records > 0
  );
  const [openIndex, setOpenIndex] = useState(1);

  const handleToggle = (index) => {
    setOpenIndex(index === openIndex ? 0 : index);
  };
  return (
    <>
      <Dialog
        sx={{
          maxWidth: "500px",
          margin: "auto",
          padding: "20px",
          // ,
        }}
        fullWidth
        open={paymentConfirmationWindow}
      >
        <div
          style={{
            boxShadow: "0 10px 15px rgba(0, 0, 0, 0.3)",
          }}
        >
          <button
            style={{
              color: "black",
              fontSize: "16px",
              padding: "10px 20px",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            onClick={() => {
              setpaymentConfirmationWindow(false);
            }}
          >
            X
          </button>

          <DialogTitle className={styles.dialogTitle}>Payment Info</DialogTitle>

          <div className={styles.dialogContent}>
            <p style={{ marginBottom: "18px" }} className={styles.priceItem}>
              Price : <span>{selectedPlanDetials.Sale_price}</span>
            </p>
            <p className={styles.priceItem} style={{ marginBottom: "18px" }}>
              GST charges @ {selectedPlanDetials.Gst_percentage}%:
              <span>+{selectedPlanDetials.gst_margin}</span>
            </p>
            <p className={styles.priceItem}>
              Service Charge
              <span>+{selectedPlanDetials.service_charge}</span>
            </p>
            <hr />
            <p
              style={{ display: "flex", justifyContent: "space-around" }}
              className={(styles.priceItem, styles.totalPrice)}
            >
              Total Price: <span>{selectedPlanDetials.final_amount_pay}</span>
            </p>
          </div>
          <div className={styles.dialogFooter}>
            <button
              className={styles.paymentBtn}
              onClick={() => {
                makePayment(selectedPlanDetials);
              }}
            >
              Make Payment
            </button>
          </div>
        </div>
      </Dialog>

      <div style={{ marginTop: "0px", paddingTop: "100px" }}>
        <div className={styles.buttonContainer}>
          <button
            className={`${styles.navButton} ${
              openIndex === 0 ? styles.active : ""
            }`}
            onClick={() => handleToggle(0)}
          >
            Events Pack
          </button>
          <button
            className={`${styles.navButton} ${
              openIndex === 1 ? styles.active : ""
            }`}
            onClick={() => handleToggle(1)}
          >
            Video Pack
          </button>
          <button
            className={`${styles.navButton} ${
              openIndex === 2 ? styles.active : ""
            }`}
            onClick={() => handleToggle(2)}
          >
            Combo Pack
          </button>
        </div>

        <motion.div
          style={{
            width: "95vw",
            display: openIndex === 0 ? "flex" : "none",
            flexWrap: "wrap",
            justifyContent: "space-around",
            margin: "auto",
            padding: "10px",
            overflowX: "auto",
            gap: "15px",
          }}
          initial={{ opacity: 0, x: -100 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 100 }}
          transition={{ duration: 0.5 }}
          className="NavigationMenuContent"
        >
          {eventPacks.map((plan, index) => (
            <>
              {plan.actual_price === 0 && plan.Sale_price === 0 ? null : (
                <div key={index} className={styles.pricingCard}>
                  <p className={styles.pricingCardTitle}>{plan.name}</p>
                  <p
                    style={{
                      textDecoration:
                        plan.actual_price < plan.Sale_price
                          ? "none"
                          : plan.Sale_price === 0
                          ? "none"
                          : "line-through",
                    }}
                    className={styles.actualPrice}
                  >
                    {plan.actual_price === 0 ||
                    plan.actual_price === plan.Sale_price ? null : (
                      <>₹{plan.actual_price}</>
                    )}
                  </p>
                  <div className={styles.salePrice}>
                    {plan.Sale_price === 0 ? null : <>₹{plan.Sale_price}</>}
                  </div>
                  <div className={styles.featuresBlock}>
                    <div className={styles.feature}>
                      <FaCheckCircle color="green" />
                      <p className={styles.ProductSpecStyle}>
                        {`${plan.num_Events} Event credits`}
                      </p>
                    </div>
                    <div className={styles.feature}>
                      <MdCancel
                        color="red"
                        style={{ fontSize: "19px", marginLeft: "-1px" }}
                      />
                      <p className={styles.ProductSpecStyle}>
                        {`${plan.num_Records} Video credits`}
                      </p>
                    </div>
                    <div className={styles.feature}>
                      <MdCancel
                        color="red"
                        style={{ fontSize: "19px", marginLeft: "-1px" }}
                      />

                      <p className={styles.ProductSpecStyle}>
                        <p>Email Support</p>
                      </p>
                    </div>
                  </div>
                  <button
                    disabled={loading ? loading : false}
                    className={styles.payBtn}
                    onClick={() => makePaymentPop(plan)}
                  >
                    Get Started
                  </button>
                </div>
              )}
            </>
          ))}
        </motion.div>

        <motion.div
          style={{
            width: "95vw",
            display: openIndex === 1 ? "flex" : "none",
            flexWrap: "wrap",
            justifyContent: "space-around",
            padding: "10px",
            margin: "auto",
            overflowX: "auto",
            gap: "15px",
          }}
          className="NavigationMenuContent"
          initial={{ opacity: 0, x: -100 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 100 }}
          transition={{ duration: 0.5 }}
        >
          {videoPacks.map((plan, index) => (
            <div key={index} className={styles.pricingCard}>
              <p className={styles.pricingCardTitle}>{plan.name}</p>
              <p
                style={{
                  textDecoration:
                    plan.actualPrice < plan.Sale_price
                      ? "none"
                      : plan.Sale_price === 0
                      ? "none"
                      : "line-through",
                }}
                className={styles.actualPrice}
              >
                {plan.actualPrice !== 0 && <>₹{plan.actual_price}</>}
              </p>
              <div className={styles.salePrice}>
                {plan.Sale_price === 0 ? null : <>₹{plan.Sale_price}</>}
              </div>
              <div className={styles.featuresBlock}>
                <div className={styles.feature}>
                  <MdCancel
                    color="red"
                    style={{ fontSize: "19px", marginLeft: "-1px" }}
                  />

                  <p className={styles.ProductSpecStyle}>
                    {`${plan.num_Events} Event credits`}
                  </p>
                </div>
                <div className={styles.feature}>
                  <FaCheckCircle color="green" />
                  <p className={styles.ProductSpecStyle}>
                    {`${plan.num_Records} Video credits`}
                  </p>
                </div>
                <div className={styles.feature}>
                  <MdCancel
                    color="red"
                    style={{ fontSize: "19px", marginLeft: "-1px" }}
                  />

                  <p className={styles.ProductSpecStyle}>
                    <p>Email Support</p>
                  </p>
                </div>
              </div>
              <button
                disabled={loading ? loading : false}
                className={styles.payBtn}
                onClick={() => makePaymentPop(plan)}
              >
                Get Started
              </button>
            </div>
          ))}
        </motion.div>

        <motion.div
          style={{
            width: "95vw",
            display: openIndex === 2 ? "flex" : "none",
            flexWrap: "wrap",
            justifyContent: "space-around",
            padding: "10px",
            margin: "auto",
            overflowX: "auto",
            gap: "15px",
          }}
          className="NavigationMenuContent"
        >
          {comboPacks.map((plan, index) => (
            <div key={index} className={styles.pricingCard}>
              <p className={styles.pricingCardTitle}>{plan.name}</p>
              <p
                style={{
                  textDecoration:
                    plan.actualPrice < plan.Sale_price
                      ? "none"
                      : plan.Sale_price === 0
                      ? "none"
                      : "line-through",
                }}
                className={styles.actualPrice}
              >
                {plan.actualPrice !== 0 && <>₹{plan.actual_price}</>}
              </p>
              <div className={styles.salePrice}>
                {plan.Sale_price === 0 ? null : <>₹{plan.Sale_price}</>}
              </div>
              <div className={styles.featuresBlock}>
                <div className={styles.feature}>
                  <FaCheckCircle color="green" />
                  <p className={styles.ProductSpecStyle}>
                    {`${plan.num_Events} Event credits`}
                  </p>
                </div>
                <div className={styles.feature}>
                  <FaCheckCircle color="green" />
                  <p className={styles.ProductSpecStyle}>
                    {`${plan.num_Records} Video credits`}
                  </p>
                </div>
                <div className={styles.feature}>
                  <FaCheckCircle color="green" />
                  <p className={styles.ProductSpecStyle}>
                    <p>Email Support</p>
                  </p>
                </div>
              </div>
              <button
                disabled={loading ? loading : false}
                className={styles.payBtn}
                onClick={() => makePaymentPop(plan)}
              >
                Get Started
              </button>
            </div>
          ))}
        </motion.div>
      </div>
    </>
  );
};

export default Payments;
