import { useEffect, useRef } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "./global.css";

const VideoPlayer = ({ options, edit }) => {
  const hlsVideoRef = useRef(null);
  const hlsPlayerRef = useRef(null);

  useEffect(() => {
    const initializePlayer = () => {
      if (hlsVideoRef.current && !hlsPlayerRef.current) {
        const videoNode = hlsVideoRef.current;

        // Initialize Video.js player
        hlsPlayerRef.current = videojs(videoNode, options, () => {
          console.log("Player is ready");
        });

        // Event listener for error handling
        hlsPlayerRef.current.on("error", (e) => {
          console.error("Video.js Error:", e);
        });
      }
    };

    const timeoutId = setTimeout(() => {
      initializePlayer();
    }, 100); // Delay to ensure video element is in DOM

    return () => {
      clearTimeout(timeoutId);
      if (hlsPlayerRef.current) {
        hlsPlayerRef.current.dispose();
        hlsPlayerRef.current = null;
      }
    };
  }, [options]);

  return (
    <div
      data-vjs-player
      style={{
        width: "500px",
        height: edit && "200px",
      }}
    >
      
      <video ref={hlsVideoRef} className="video-js video-js-video-player" />
    </div>
  );
};

export default VideoPlayer;
