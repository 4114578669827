export const jwtDecode = (token) => {
  // Split the token into its three parts: header, payload, and signature
  const base64Url = token.split(".")[1];

  // Convert Base64Url to Base64 by replacing URL-safe characters
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");

  // Decode the Base64 string to a JSON string
  const jsonPayload = atob(base64);

  // Parse and return the decoded JSON
  return JSON.parse(jsonPayload);
};

export const getDataFromToken = async (token) => {
  const decoded = jwtDecode(token);
  return decoded;
};

export const getUserIdFromToken = async (token) => {
  const user = await jwtDecode(token);
  return user.id;
};

export const getUserTypeFromToken = async (token) => {
  const user = await jwtDecode(token);
  return user.user_type;

  // change user_type name key as per your response  is 1-2-3
};

export const isTokenExpired = (token) => {
  if (!token) {
    return false;
  }
  const decoded = jwtDecode(token);
  const currentTime = Date.now() / 1000;
  return decoded.exp > currentTime;
};
