import { useState, useEffect } from "react";
import { useAppDataContext } from "hooks/useappdatacontext";
import DOMPurify from "dompurify";

const NavBar = ({ fnStyle, title }) => {
  // States
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  // Contexts
  const { singleEventData } = useAppDataContext();

  // Update `isMobile` on window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const cleanHtml = (check) => {
    const htmlString = check;
    const sanitizedHtml = DOMPurify.sanitize(htmlString);
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = sanitizedHtml;
    const plainText = tempDiv.innerText || tempDiv.textContent;
    return plainText;
  };
  return (
    <nav
      style={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row", // Flex direction based on screen size
        alignItems: "center",
        padding: "20px",
        fontFamily: "Roboto",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        fontSize: "1.5rem",
        width: "100%",
        position: "relative", // Needed for absolute positioning of the text
      }}
    >
      <a href="/">
        <img
          src="https://d1gmn9lhl3xm2s.cloudfront.net/static/logo/neolLogo.jpg"
          style={{
            height: "40px",
            width: "150px",
            marginRight: isMobile ? "0" : "20px", // Adjust margin on mobile
            marginBottom: isMobile ? "10px" : "0", // Adjust margin on mobile
          }}
          alt="nav-logo"
        />
      </a>

      <p
        style={{
          position: "absolute", // Keeping the title in absolute position
          top: isMobile ? "50%" : "29px", // Vertically center the title on mobile, keep fixed for desktop
          display: isMobile ? "none" : "inline",
          left: "50%", // Horizontal center position
          transform: "translateX(-50%)", // Offset the element by 50% of its width to perfectly center it
          textAlign: "center", // Center the text inside the <p> element
          margin: 0, // Remove any default margin from <p>
        }}
      >
        {cleanHtml(singleEventData?.title)}
      </p>
    </nav>
  );
};

export default NavBar;
