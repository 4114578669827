import React, { useState, useEffect, useRef } from "react";
import img from "../../assets/images/maintenance-pana.png";

const Game = () => (
  <div
    style={{
      width: "100vw",
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "0px",
      backgroundColor: "#f0f0f0",
    }}
  >
    <h4 style={{ color: "#407BFF", fontStyle: "italic", fontSize: "24px" }}>
      Ops... Site Under Maintenance!
    </h4>
    <img style={{ width: "350px" }} src={img} alt="Develop Img" />
  </div>
);

export default Game;
